module.exports = function menu() {
    var burger = document.querySelector('.burger');
    var menu = document.querySelector(".navigation")
    var head = document.querySelector('#header');
    var dropItem = document.querySelector('.dropItem');
    burger.addEventListener('click', () => {
        if (burger.classList.contains('burger-active')) {
            burger.classList.remove('burger-active');
            menu.style.display = 'none';
        } else {
            burger.classList.add('burger-active');
            menu.style.display = 'flex';
        }
    })
    dropItem.addEventListener('mouseover', () => {
        head.style.height = '140px'
    });
    dropItem.addEventListener('mouseout', () => {
        head.style.height = '80px'
    });
    window.onscroll = function () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 50) {
            head.style.backgroundColor = 'rgba(233, 239, 240, 0.8)';
        } else {
            head.style.backgroundColor = 'rgba(233, 239, 240, 1)';
        }
    }
};
