var btnBack = document.querySelector('.main-slider-back');
var btnNext = document.querySelector('.main-slider-next');
var slides = document.querySelectorAll('.main-section');
var slidesArr = Array.prototype.slice.call(slides);
var slider = document.querySelector('.main-slider')


function changeSlide() {
    if (slidesArr[0].classList.contains('active-slide')) {
        //скрываем первый слайд
        slidesArr[0].classList.remove('active-slide');
        //показыаем второй слайд
        slidesArr[1].classList.add('active-slide')
    } else if (slidesArr[1].classList.contains('active-slide')) {
        //скрываем второй слайд
        slidesArr[1].classList.remove('active-slide');
        //показываем первый слайд
        slidesArr[0].classList.add('active-slide');
    }
    var slideHeight = slidesArr[1].offsetHeight;
    slider.style.height = slideHeight + 'px';
}

module.exports = function mainSlider() {


    if (window.screen.width > 425) {

        //показываем кнопки и первый слайд, а так же задаем высоту слайдера по высоте активного слайда
        slidesArr[0].classList.add('active-slide');
        btnBack.style.opacity = '1';
        btnNext.style.opacity = '1';
        var slideHeight = slidesArr[1].offsetHeight;
        slider.style.height = slideHeight + 'px';

        //нажатие на кнопку NEXT и BACK
        btnNext.addEventListener('click', changeSlide)
        btnBack.addEventListener('click', changeSlide)
    }


}



